<template>
  <div class="section">
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Detalle de nota</h1>
        </div>
      </div>
    </nav>

    <template v-if="loading">
      <loading />
    </template>

    <template v-else>
      <div class="levelBack">
        <router-link
          :to="{ name: 'blog' }"
          class="link-back"
        >
          <i class="material-icons">keyboard_arrow_left</i>
          <span> Regresar </span>
        </router-link>
      </div>
      <div class="box">
        <div class="level-right">
          <div class="control">
            <button
              v-if="!isEdit"
              @click="shoModalEditPost"
              class="button button-givu"
            >
              Editar
            </button>
          </div>
        </div>
        <form-edit-post
          v-if="isEdit"
          :postData="postData"
          :postId="parseInt(postId)"
          :editPost="updatePost"
          :close="shoModalEditPost"
        />
        <detail-post
          v-else
          :postData="postData"
        />
      </div>
    </template>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'EditBlog',
  components: {
    DetailPost: () => import('@/components/Blog/DetailPost'),
    FormEditPost: () => import('@/components/Blog/FormEditPost'),
    Loading: () => import('@/components/ui/Loading')
  },
  data () {
    return {
      loading: false,
      isEdit: false,
      postId: this.$route.params.postId,
      postData: {}
    }
  },
  methods: {
    ...mapActions(['getPostDetail', 'editPost']),
    shoModalEditPost () {
      this.isEdit = !this.isEdit
    },
    async updatePost ({ postId, data }) {
      const success = await this.editPost({ postId, data })
      if (success) {
        this.getPostDetail(parseInt(this.postId))
          .then(resp => {
            this.postData = resp.payload
            this.exist = !!resp.payload || false
            this.loading = false
          })
        this.shoModalEditPost()
      }
    }
  },
  beforeMount () {
    this.loading = true
    this.getPostDetail(parseInt(this.postId))
      .then(resp => {
        this.postData = resp.payload
        this.exist = !!resp.payload || false
        this.loading = false
      })
  }
}
</script>

<style scoped>
  .section {
    padding: 0 20px 0 20px;
  }
  .levelBack {
    padding-bottom: 10px ;
  }
  .link-back {
    display: flex;
    align-items: center;
  }
</style>
